@use 'colors';

/******************* DESIGN SYSTEM SHADOWS **********************/

@mixin shadow {
    box-shadow: 0 1px 4px 1px rgba(colors.$color-accent-extra-dark, 0.2);
}

@mixin shadow-dark {
    box-shadow: 0 1px 4px 1px rgba(colors.$color-accent-extra-dark, 0.3);
}

@mixin shadow-hover {
    box-shadow: 0 2px 4px 1px rgba(colors.$color-accent-extra-dark, 0.3);
}

@mixin shadow-strong {
    box-shadow: 0 1px 15px 1px rgba(colors.$color-accent-extra-dark, 0.2);
}

/* **************************************************************** */

@mixin shadow-box {
    @include shadow-dark;

    border-radius: 16px;
}

@mixin skeleton-loading {
    border-radius: 12px;
    background-image: linear-gradient(
        90deg,
        colors.$color-accent-extra-light 0,
        colors.$color-accent-light 40px,
        colors.$color-accent-extra-light 80px
    );
    background-size: 80vw;
    animation: mat-table__skeleton-row--animation infinite linear;
    animation-duration: 1.2s;
}

@mixin mat-tabs {
    ::ng-deep
        .mat-mdc-tab-body.mat-mdc-tab-body-active
        .mat-mdc-tab-body-content {
        overflow: hidden;
    }

    ::ng-deep .mat-mdc-tab.mdc-tab {
        min-width: 90px;
        padding-left: 40px;
        padding-right: 40px;
    }

    ::ng-deep .mat-mdc-tab.mdc-tab .mdc-tab__text-label {
        color: colors.$color-primary-light;
    }

    ::ng-deep .mat-mdc-tab.mdc-tab:hover .mdc-tab__text-label {
        color: colors.$color-primary;
    }

    ::ng-deep .mat-mdc-tab.mdc-tab.mdc-tab--active .mdc-tab__text-label {
        color: colors.$color-primary-dark;
    }

    ::ng-deep .mat-mdc-tab.mdc-tab.mdc-tab--active:hover .mdc-tab__text-label {
        color: colors.$color-primary;
    }
}

@mixin skeleton-loading-primary {
    border-radius: 12px;
    background-image: linear-gradient(
        90deg,
        colors.$color-primary-light 0,
        colors.$color-primary 40px,
        colors.$color-primary-light 80px
    );
    background-size: 80vw;
    animation: mat-table__skeleton-row--animation infinite linear;
    animation-duration: 1.2s;
}

@mixin video-dialog-width {
    @apply min-w-300;
    @apply md:max-w-screen-sm;
    @apply lg:min-w-screen-sm;
    @apply lg:max-w-screen-md;
    @apply xl:max-w-screen-md;

    width: 100%;
}

@mixin scrollbar-style(
    $base-color: var(--scrollbar-base-color),
    $thumb-color: var(--scrollbar-thumb-color)
) {
    scrollbar-color: $thumb-color $base-color;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
        background-color: $base-color;
    }

    &::-webkit-scrollbar-thumb {
        background: $thumb-color;
        border-radius: 4px;
        box-shadow: inset 0 0 0 1px $base-color;
    }
}

@mixin movement-state-colors {
    &.as-idle {
        @apply text-error;
    }

    &.as-inactive {
        @apply text-accent;
    }

    &.as-moving {
        @apply text-success-dark;
    }
}
