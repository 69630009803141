@use 'sass:map';
@use 'sass:string';
@use '@angular/material' as mat;

// Make sure the typography definitions here are in sync with tailwind.config.js
// define-typography-level(font size, line height, weight)

$vtg-typography: mat.m2-define-typography-config(
    $font-family: 'DaxPro, "Helvetica Neue", sans-serif',
    // headline-5 (former: headline): .mat-h1, .mat-headline-5, .mat-typography h1
    $headline-5: mat.m2-define-typography-level(60px, 60px, 400),
    // headline-6 (former: title): .mat-h2, .mat-headline-6, .mat-typography h2
    $headline-6: mat.m2-define-typography-level(40px, 40px, 400),
    // subtitle-1 (former: subheading-2): .mat-h3, .mat-subtitle-1, .mat-typography h3
    $subtitle-1: mat.m2-define-typography-level(24px, 32px, 500),
    // subtitle-2 (former: subheading-1): .mat-h4, .mat-subtitle-2, .mat-typography h4
    $subtitle-2: mat.m2-define-typography-level(24px, 32px, 400),
    // body-2: .mat-body-2 // used for all texts
    $body-2: mat.m2-define-typography-level(16px, 20px, 400),
    // body-1: .mat-body, .mat-body-1, .mat-typography // used in input fields and options
    $body-1: mat.m2-define-typography-level(16px, 20px, 400),
    // caption: .mat-small, .mat-caption
    $caption: mat.m2-define-typography-level(12px, 16px, 500),
    // button:
    // .mat-button, .mat-raised-button, .mat-mdc-icon-button, .mat-stroked-button,
    // .mat-flat-button, .mat-fab, .mat-mini-fab
    $button: mat.m2-define-typography-level(16px, 20px, 500),
    // Line-height must be unit-less fraction of the font-size.
);

@mixin complement-typography {
    // Typography sets not available through Angular Material.
    // @see node_modules/@angular/material/core/typography/_typography.scss

    // Doubling selection in first and last selectors to increase specificity
    // and override Angular Material styles.
    .mat-h1.mat-h1,
    .mat-headline-5,
    .mat-typography.mat-typography h1 {
        font-size: 60px;
        font-weight: 400;
        line-height: 60px;
        font-family: string.unquote(map.get($vtg-typography, font-family));
        margin: 0 0 16px;
    }

    .mat-h2.mat-h2,
    .mat-headline-6,
    .mat-typography.mat-typography h2 {
        font-size: 40px;
        font-weight: 400;
        line-height: 40px;
        font-family: string.unquote(map.get($vtg-typography, font-family));
    }

    .mat-h3.mat-h3,
    .mat-subtitle-1,
    .mat-typography.mat-typography h3 {
        font-size: 24px;
        font-weight: 500;
        line-height: 32px;
        font-family: string.unquote(map.get($vtg-typography, font-family));
    }

    .mat-h4.mat-h4,
    .mat-typography.mat-typography h4 {
        font-size: 24px !important;
        font-weight: 400 !important;
        line-height: 32px !important;
        font-family: string.unquote(map.get($vtg-typography, font-family));
    }

    .mat-h5.mat-h5,
    .mat-smallheading,
    .mat-typography.mat-typography h5 {
        font-size: 20px;
        font-weight: 500;
        line-height: 32px;
        font-family: string.unquote(map.get($vtg-typography, font-family));
    }

    // former body-2
    .text-body-3,
    .mat-body-strong.mat-body-strong {
        font-size: 16px !important;
        font-weight: 500 !important;
        line-height: 20px !important;
        font-family: string.unquote(map.get($vtg-typography, font-family));
    }

    // Tailwind
    .text-subtitle-1,
    .text-smallheading,
    .text-body-3,
    .text-caption,
    .text-button {
        font-weight: 500;
    }

    .text-caption-1 {
        font-weight: 400;
    }

    // Correct Angular Material elements
    .mat-option {
        @apply text-body-1;
    }
}
