@use '@angular/material' as mat;
@use 'theme';
@use 'mixins';

@tailwind components;
@tailwind utilities;

@layer utilities {
    .auto-cols-max-fr {
        grid-auto-columns: minmax(max-content, 1fr);
    }

    .scrollbar,
    .scrollbar-primary {
        @include mixins.scrollbar-style;
    }

    .scrollbar-primary {
        --scrollbar-base-color: #{mat.m2-get-color-from-palette(
                theme.$vtg-primary,
                900
            )};
        --scrollbar-thumb-color: #{theme.$color-primary};
    }

    // In order to overwrite Material
    .app-h1.app-h1,
    .mat-h1.mat-h1,
    .mat-headline-5,
    .text-headline-5,
    .mat-typography.mat-typography h1 {
        @apply text-4xl;
        @apply md:text-5xl;
        @apply xl:text-6xl;
    }

    // In order to overwrite Material
    .app-h2.app-h2,
    .mat-h2.mat-h2,
    .mat-typography.mat-typography h2 {
        @apply mb-6 text-3xl;
        @apply md:mb-8;
        @apply md:mb-10 xl:text-5xl;
    }

    .text-headline-6,
    .mat-headline-6 {
        @apply text-3xl;
        @apply xl:text-5xl;
        margin-bottom: 0px !important;
    }

    .app-text {
        @apply text-lg;
        @apply md:text-xl;
    }
}

.container {
    box-sizing: border-box;
}
