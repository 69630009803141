@use '@angular/material' as mat;
@use 'sass:meta';
@use 'tailwind';
@use 'mixins';
@use 'ag-grid';
@forward 'ag-grid';
@use 'theme';
@forward 'theme';
@use 'fonts/dax-pro';
@use 'fonts/material-icons';
@use 'chartist/dist/scss/chartist.scss';

/* stylelint-disable no-invalid-position-at-import-rule */
@import 'maplibre-gl/dist/maplibre-gl.css';
@import 'leaflet/dist/leaflet.css';
@import 'leaflet-draw/dist/leaflet.draw.css';
@import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
@import 'leaflet.markercluster/dist/MarkerCluster.css';
@import 'leaflet';
@import 'styles/leaflet';

@include mat.core;
@include theme.complement-typography;
@include mat.all-component-typographies(theme.$vtg-typography);
@include mat.all-component-themes(theme.$vtg-theme);
@include meta.load-css('skeleton-loading');

:root {
    --scrollbar-base-color: #{theme.$color-accent-extra-light};
    --scrollbar-thumb-color: #{theme.$color-accent-light};
    --nav-bar-height: 56px;
    --overlay-height: calc(100vh - var(--nav-bar-height));
}

.mat-icon {
    color: var(--mat-icon-color, theme.$color-accent-dark);
    line-height: 1;
}

.mat-accent,
.mat-warn,
.mat-mdc-button-base {
    --mat-icon-color: currentcolor;
}

.mat-error,
.mat-form-field-invalid,
.mat-form-field-invalid .mat-mdc-button-base {
    --mat-icon-color: #{theme.$color-error};
}

a,
a:visited {
    color: theme.$color-primary;
}

a:hover {
    color: theme.$color-primary-extra-light;
}

a:focus-visible {
    box-shadow: 0 0 0 1px theme.$color-primary-dark inset;
    outline: none;
}

strong {
    font-weight: 500;
}

.light-border-bottom {
    border-bottom: 1px solid theme.$color-accent-light;
}

// ---------------------
// Form Field Hint
// ---------------------

.mat-mdc-form-field-hint {
    @apply text-primary;
}

// ---------------------
// Radio Button
// ---------------------

.mat-mdc-radio-button {
    .mdc-label {
        white-space: nowrap;
    }
}

.mat-mdc-radio-button
    .mdc-radio
    .mdc-radio__native-control:enabled
    + .mdc-radio__background
    .mdc-radio__outer-circle {
    border-color: theme.$color-primary !important;
}

.mat-mdc-radio-button
    .mdc-radio
    .mdc-radio__native-control:enabled
    + .mdc-radio__background
    .mdc-radio__inner-circle {
    border-color: theme.$color-primary !important;
}

.mat-mdc-radio-button.mat-accent:not(.mdc-radio--disabled)
    .mat-radio-ripple
    .mat-ripple-element {
    background-color: theme.$color-primary !important;
}

// ---------------------
// Checkbox
// ---------------------

.mat-mdc-checkbox:hover .mdc-checkbox__ripple {
    background-color: transparent !important;
}

.mat-mdc-checkbox:not(.checkbox-secondary, .mat-mdc-checkbox-disabled)
    .mdc-checkbox__background {
    border-color: theme.$color-primary !important;
}

.mat-mdc-checkbox.checkbox-secondary.mat-mdc-checkbox-checked:not(
        .mat-mdc-checkbox-disabled
    ) {
    .mdc-checkbox__background {
        background-color: theme.$color-white !important;
    }
}

.mat-mdc-checkbox.checkbox-secondary {
    &:not(.mat-mdc-checkbox-disabled) .mdc-checkbox__background {
        border-color: theme.$color-white !important;
    }

    &:not(.mat-mdc-checkbox-disabled) .mdc-checkbox__checkmark-path {
        stroke: theme.$color-primary;
    }

    .mdc-checkbox__mixedmark {
        background-color: theme.$color-primary;
    }

    &.mat-mdc-checkbox-disabled .mdc-checkbox__checkmark-path {
        stroke: theme.$color-primary-dark;
    }
}

.mat-mdc-checkbox.checkbox-secondary[checked='true'] {
    &:not(.mat-mdc-checkbox-disabled) .mdc-checkbox__background {
        background-color: theme.$color-white !important;
    }
}

.mat-mdc-checkbox.checkbox-secondary[indeterminate] {
    &:not(.mat-mdc-checkbox-disabled) .mdc-checkbox__background {
        background-color: theme.$color-white !important;
        border-color: theme.$color-white !important;
    }

    &:not(.mat-mdc-checkbox-disabled) .mdc-checkbox__mixedmark {
        border-color: theme.$color-primary !important;
    }
}

// ---------------------
// Slide Toggle
// ---------------------
.mat-mdc-slide-toggle {
    .mdc-switch__track::before,
    .mdc-switch__track::after {
        background-color: theme.$color-accent-light !important;
    }

    .mdc-switch__icons {
        display: none;
    }
}

.mdc-switch.mdc-switch--unselected:enabled .mdc-switch__handle::after {
    background-color: theme.$color-accent-dark !important;
}

.mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after {
    background-color: theme.$color-primary !important;
}

.mdc-switch:disabled .mdc-switch__track {
    opacity: 1 !important;
}

.mdc-switch:disabled.mdc-switch.mdc-switch--selected:enabled
    .mdc-switch__handle::after,
.mdc-switch:disabled.mdc-switch.mdc-switch--unselected:enabled
    .mdc-switch__handle::after {
    background-color: theme.$color-accent-medium !important;
}

// ---------------------
// Table
// ---------------------

.mat-tab-labels {
    background: theme.$color-white;
}

.mat-tab-label {
    color: theme.$color-primary;
    font-weight: 500;
}

.mat-tab-links {
    background: transparent;
}

.mat-tab-link {
    color: theme.$color-primary;
    font-weight: 500;
}

.mat-tab-link.mat-tab-label-active {
    opacity: 1;
    background: theme.$color-white;
}

// ---------------------
// Dropdowns
// ---------------------

.mat-select-panel {
    margin-top: 36px !important;

    &.appearance-fill {
        margin-top: 32px !important;
    }
}

.mat-mdc-option.mdc-list-item--selected:not(
        .mdc-list-item--disabled,
        .mat-mdc-option-multiple
    ) {
    background-color: theme.$color-accent-light !important;
}

.mat-mdc-form-field:not(.mat-form-field-disabled)
    .mat-mdc-floating-label.mdc-floating-label {
    color: theme.$color-accent-dark !important;
    will-change: unset;
}

.mat-mdc-form-field-focus-overlay {
    background-color: white;
}

.mat-mdc-optgroup-label {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: theme.$color-accent;
}

.mat-form-field-invalid:not(.mat-form-field-disabled)
    .mat-mdc-floating-label.mdc-floating-label {
    color: theme.$color-error !important;
}

.mat-mdc-form-field {
    width: 100%;

    .mat-mdc-form-field-icon-suffix .mat-spinner {
        position: absolute;
        right: 0;
        bottom: 4px;
        background: theme.$color-white;
    }
}

.mdc-text-field--filled:not(
        .mdc-text-field--disabled,
        .mdc-text-field--invalid,
        .mdc-text-field--focused
    ):hover
    .mdc-line-ripple::before {
    border-bottom-color: theme.$color-primary !important;
}

.mdc-text-field--filled.mdc-text-field--disabled:not(.mdc-text-field--focused)
    .mdc-line-ripple::before {
    border-bottom-color: theme.$color-accent-dark !important;
}

// fixes loading bar showing up in dropdown
mat-option.loading-option .mdc-list-item__primary-text {
    opacity: 1 !important;
    flex-grow: 1;
}

// Invalid

.mat-form-field-invalid {
    .mdc-text-field--filled:not(
            .mdc-text-field--disabled,
            .mdc-text-field--focused
        ):hover
        .mdc-line-ripple::before {
        border-bottom-color: theme.$color-error-dark !important;
    }

    .mdc-text-field--filled:not(
            .mdc-text-field--disabled,
            .mdc-text-field--focused
        )
        .mdc-line-ripple::before {
        border-bottom-color: theme.$color-error !important;
    }

    .mdc-text-field--outlined:not(.mdc-text-field--disabled):hover {
        .mdc-notched-outline__leading,
        .mdc-notched-outline__notch,
        .mdc-notched-outline__trailing {
            border-color: theme.$color-error-dark !important;
        }
    }

    .mdc-text-field--focused.mdc-text-field--focused {
        .mdc-notched-outline__leading,
        .mdc-notched-outline__notch,
        .mdc-notched-outline__trailing {
            border-color: theme.$color-error-dark !important;
        }
    }

    .mdc-text-field--outlined:not(.mdc-text-field--disabled) {
        .mdc-notched-outline__leading,
        .mdc-notched-outline__notch,
        .mdc-notched-outline__trailing {
            border-color: theme.$color-error !important;
        }
    }
}

.mat-mdc-form-field > .mdc-text-field--outlined,
.mdc-text-field--outlined:hover {
    [matformfieldnotchedoutline] .mdc-notched-outline__notch {
        border-left-width: 0 !important;
    }
}

// Flat

.mat-mdc-form-field:not(.mat-form-field-disabled, .mdc-text-field--invalid) {
    .mat-mdc-text-field-wrapper {
        background: theme.$color-white;
    }

    .mat-mdc-select-arrow {
        color: theme.$color-primary;
    }

    &:hover .mat-form-field-underline .mat-form-field-ripple {
        background-color: theme.$color-primary;
    }
}

.mat-mdc-form-field-icon-prefix > .mat-icon,
.mat-mdc-form-field-icon-suffix > .mat-icon {
    padding-right: 0 !important;
    padding-bottom: 0 !important;
    padding-top: 0 !important;
}

.mat-mdc-form-field-icon-prefix > app-loading-indicator {
    padding-left: 12px;
}

// Flat -- disabled

.mat-mdc-form-field.mat-form-field-disabled {
    .mat-mdc-floating-label.mdc-floating-label {
        color: theme.$color-accent-medium;
    }

    .mat-mdc-text-field-wrapper {
        background: theme.$color-white;
    }

    .mat-mdc-form-field-icon-suffix .mat-icon {
        color: theme.$color-accent-medium;
    }
}

// Flat -- invalid

.mat-form-field-invalid:not(.mat-form-field-disabled):hover
    .mat-mdc-floating-label.mdc-floating-label {
    color: theme.$color-error-dark !important;
}

.mat-mdc-form-field.mat-form-field-invalid {
    .mat-mdc-floating-label.mdc-floating-label {
        color: theme.$color-error !important;
    }

    .mat-mdc-select-arrow {
        color: theme.$color-error;
    }

    &:hover .mat-mdc-select-arrow {
        color: theme.$color-error-dark !important;
    }
}

// Stroked

.mat-form-field-appearance-outline:not(.mdc-text-field--invalid) {
    .mdc-text-field--focused {
        .mdc-notched-outline__leading,
        .mdc-notched-outline__notch,
        .mdc-notched-outline__trailing {
            border-color: theme.$color-primary;
        }
    }
}

.mat-form-field-appearance-outline:not(.mdc-text-field--disabled) {
    .mat-mdc-text-field-wrapper {
        border-radius: 6px;
    }
}

.mdc-text-field--outlined:not(
        .mdc-text-field--disabled,
        .mdc-text-field--invalid
    ):hover {
    .mdc-notched-outline__leading,
    .mdc-notched-outline__notch,
    .mdc-notched-outline__trailing {
        border-color: theme.$color-primary !important;
    }
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline {
    .mdc-notched-outline__leading,
    .mdc-notched-outline__notch,
    .mdc-notched-outline__trailing {
        border-color: theme.$color-accent-light;
    }
}

// multiselect

.mat-mdc-option .mat-icon,
.mat-mdc-option .mat-pseudo-checkbox-full {
    color: theme.$color-primary;
}

// misc
.mat-mdc-autocomplete-panel {
    .mat-mdc-option.mdc-list-item--disabled {
        .mdc-list-item__primary-text.mdc-list-item__primary-text {
            width: 100%;
        }
    }
}

// ---------------
//  Datepicker
// ---------------

.mat-form-field-invalid .date-picker__icon {
    color: theme.$color-error !important;
}

// Tooltip
.mat-mdc-tooltip div.mdc-tooltip__surface {
    background-color: theme.$color-primary;
}

.mdc-tooltip {
    border-radius: 6px;
    padding: 2px;
}

// ---------------------
// Snackbar
// ---------------------

.mat-mdc-snack-bar-container {
    border-radius: 4px !important;
    display: flex;

    &.primary {
        --mdc-snackbar-container-color: #{theme.$color-primary-extra-dark};
        --mdc-snackbar-supporting-text-color: #{theme.$color-white};
    }

    &.secondary {
        --mdc-snackbar-container-color: #{theme.$color-white};
        --mdc-snackbar-supporting-text-color: #{theme.$color-accent-extra-dark};
    }
}

.snackbar--primary {
    .mat-icon {
        color: theme.$color-white;
    }
}

.snackbar--secondary {
    .primary-color {
        color: theme.$color-primary;
    }

    .success-color {
        color: theme.$color-success;
    }

    .warning-color {
        color: theme.$color-caution;
    }

    .error-color {
        color: theme.$color-error-dark;
    }
}

// ---------------------
// Dialogs
// ---------------------

.cookie-dialog {
    @layer utilities {
        transform: translateY(32px);
        height: calc(90% - 64px);
        max-width: calc(100vw - 16px) !important;

        @screen md {
            height: calc(90% - 160px);
            max-width: 80vw;
        }
    }

    .mat-mdc-dialog-surface {
        overflow: overlay;
    }
}

.mat-mdc-dialog-surface {
    @apply p-6;
}

.mdc-dialog__content.mdc-dialog__content {
    padding-left: 0 !important;
    padding-right: 0 !important;
    color: theme.$color-accent-extra-dark !important;
}

.mdc-dialog__actions {
    padding: 0 !important;

    .button-group {
        padding: 0 !important;
    }
}

.compact-dialog {
    .mdc-dialog__content.mdc-dialog__content {
        @apply -m-4 p-4;
    }
}

.video-dialog {
    width: 100vw;

    .mat-mdc-dialog-surface.mat-mdc-dialog-surface {
        padding: 12px;
    }

    @layer utilities {
        &.video-dialog {
            max-width: min(80vw, 100vh) !important;

            @screen lg {
                max-width: min(#{theme('screens.md')}, 100vh) !important;
            }

            @screen xl {
                max-width: min(#{theme('screens.lg')}, 100vh) !important;
            }
        }
    }
}

vg-player {
    background-color: transparent !important;
}

.dialog--confirm {
    .mat-mdc-dialog-surface.mat-mdc-dialog-surface {
        @apply rounded-2xl;
    }

    .mat-mdc-dialog-title.mat-mdc-dialog-title {
        @apply font-medium text-subtitle-1 pl-0 pr-0;
    }

    .mat-mdc-dialog-actions {
        @apply justify-between;
    }
}

// ---------------------
// Misc
// ---------------------

body {
    margin: 0;
    font-family: DaxPro, 'Helvetica Neue', sans-serif;
    background-color: theme.$color-white;
}

.icon-text {
    align-items: center;
    display: flex;

    .mat-icon {
        &:first-child {
            margin-right: 8px;
        }

        &:last-child {
            margin-left: 8px;
        }
    }
}

.text-link {
    align-items: center;
    cursor: pointer;
    display: inline-flex;
    font-weight: 500;

    &:hover,
    &:focus {
        color: theme.$color-primary-dark;
    }
}

.landing-page-header {
    @layer utilities {
        top: -24px !important;
    }
}

.skeleton-loading {
    @include theme.skeleton-loading;
    @apply text-transparent;
}

.mat-mdc-chip-action-label {
    font-weight: 500 !important;
}

// ---------------------
// Overlay
// ---------------------
.cdk-overlay-container {
    z-index: var(--cdk-overlay-container-z-index, 1000);
}

.animated-overlay {
    overflow: overlay;
}

// ---------------------
// Tabs
// ---------------------
.mat-mdc-tab-header.mat-mdc-tab-nav-bar-stretch-tabs a.mat-mdc-tab-link {
    flex-grow: 0;
    min-width: 160px;
}

.mat-mdc-tab-link.mdc-tab--active span.mdc-tab__text-label {
    color: theme.$color-accent-extra-dark;
}

.mat-mdc-tab-nav-bar:not(.mat-accent) {
    .mat-mdc-tab-link.mdc-tab--active span.mdc-tab__text-label {
        color: theme.$color-primary;
    }

    .mat-mdc-tab-link span.mdc-tab__text-label {
        color: theme.$color-primary-light;
    }

    a.mat-mdc-tab-link:hover span.mdc-tab__text-label {
        color: theme.$color-primary-extra-light;
    }
}

.mat-mdc-tab-header {
    border-bottom: 1px solid rgba(0, 0, 0, 12%);
}
